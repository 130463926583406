<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">客户中心</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="创建日期" class="searchboxItem ci-full">
              <span class="itemLabel">创建日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="审核状态" class="searchboxItem ci-full">
              <span class="itemLabel">审核状态:</span>
              <el-select
                clearable
                size="small"
                v-model="auditStatus"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in applyStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="name"
              />

              <el-table-column
                label="联系方式"
                align="center"
                show-overflow-tooltip
                prop="mobile"
              />
              <el-table-column
                label="单位名称"
                align="center"
                show-overflow-tooltip
                min-width="150px"
                prop="unitName"
              />
              <el-table-column
                label="备注"
                align="center"
                show-overflow-tooltip
                prop="remark"
                min-width="150px"
              />
              <el-table-column
                label="创建时间"
                align="center"
                show-overflow-tooltip
                min-width="150px"
                prop="createTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="审核状态"
                align="center"
                show-overflow-tooltip
                prop="auditStatus"
                min-width="150"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("APPLY_AUDIT_STATUS", scope.row.auditStatus)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="不通过原因"
                align="center"
                show-overflow-tooltip
                prop="reason"
                min-width="150"
              />
              <el-table-column
                label="附件"
                align="center"
                show-overflow-tooltip
                prop="fileNum"
                min-width="150"
              >
                <template slot-scope="scope">
                  <el-image
                    style="width: 30px; height: 30px;margin:0 5px"
                    :src="scope.row.compLicenseUrl"
                    :preview-src-list="[scope.row.compLicenseUrl,scope.row.idCardFrontUrl,scope.row.idCardBackUrl]"
                  >
                    <div slot="error" class="image-slot">
                      <!-- <i class="el-icon-folder-delete"></i> -->
                    </div>
                  </el-image>
                  <el-image
                    style="width: 30px; height: 30px;margin:0 5px"
                    :src="scope.row.idCardFrontUrl"
                    :preview-src-list="[scope.row.idCardFrontUrl,scope.row.idCardBackUrl,scope.row.compLicenseUrl]"
                  >
                    <div slot="error" class="image-slot">
                      <!-- <i class="el-icon-folder-delete"></i> -->
                    </div>
                  </el-image>
                  <el-image
                    style="width: 30px; height: 30px; margin:0 5px"
                    :src="scope.row.idCardBackUrl"
                    :preview-src-list="[scope.row.idCardBackUrl,scope.row.compLicenseUrl,scope.row.idCardFrontUrl]"
                  >
                    <div slot="error" class="image-slot">
                      <!-- <i class="el-icon-folder-delete"></i> -->
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                fixed="right"
                width="200"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    :disabled="scope.row.auditStatus == '20'"
                    @click="handleAudit(scope.row.applyId, '20')"
                    >审核通过</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    :disabled="scope.row.auditStatus == '20' || scope.row.auditStatus == '30'"
                    @click="handleAudit(scope.row.applyId, '30')"
                    >审核不通过</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 上传附件 - loading -->
    <el-dialog title="审核不通过" :visible.sync="auditDialog" width="35%">
      <el-form label-width="100px" ref="ruleForm" :model="ruleForm">
        <el-form-item label="不通过原因" prop="reason">
          <el-input
            v-model="ruleForm.reason"
            type="textarea"
            size="small"
            placeholder="请输入不通过原因"
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button class="bgc-bv" @click="canCleForm('Form')">取消</el-button>
        <el-button class="bgc-bv" @click="getSure()">确定</el-button>
      </span>
    </el-dialog>
    <!-- 预览附件 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoading"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert2"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div
          v-else-if="
            fileType == 'png' || fileType == 'jpg' || fileType == 'jpeg'
          "
        >
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
        <div v-else>
          <video
            :src="ImgSrc"
            autoplay
            controls
            width="100%"
            height="500px"
          ></video>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "activeConfigurationList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      createTime: "", //时间
      auditStatus: "", //状态
      applyStatusList: [], //状态数据
      auditDialog: false,
      ruleForm: {
        reason: "", //不通过原因
      },
      auditStatus1:'',
    };
  },
  watch: {},
  created() {
    this.getApplyStatusList();
  },
  computed: {},
  mounted() {},
  methods: {
    // 初始化获取评论列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.auditStatus) {
        params.auditStatus = this.auditStatus;
      }
      if (this.createTime) {
        params.createTimeStart = this.createTime[0] + " " + "00:00:00";
        params.createTimeEnd = this.createTime[1] + " " + "23:59:59";
      }
      this.doFetch({
        url: "/biz/cooperate/apply/pageList",
        params,
        pageNum,
      });
    },
    //获取码值赠送类别
    getApplyStatusList() {
      const List = this.$setDictionary("APPLY_AUDIT_STATUS", "list");
      for (const key in List) {
        this.applyStatusList.push({
          value: key,
          label: List[key],
        });
      }
    },
    //审核通过||不通过
    handleAudit(applyId, auditStatus) {
      this.applyId = applyId;
      this.auditStatus1 = auditStatus;
      if (auditStatus == "20") {
        this.doAudit(applyId, auditStatus);
      } else {
        this.auditDialog = true;
      }
    },
    doAudit(applyId, auditStatus) {
      let parameter = {
        applyId,
        auditStatus,
      };
      if (auditStatus == "30") {
        parameter.reason = this.ruleForm.reason;
      }
      this.$post("/biz/cooperate/apply/audit", parameter).then((ret) => {
        if (ret.status == 0) {
          if (auditStatus == "20") {
            this.$message.success("审核通过");
          } else {
            this.$message.success("审核不通过");
             this.auditDialog = false;
          }
          this.getData(-1);
        }
      });
    },
    //确认
    getSure() {
      this.doAudit(this.applyId, this.auditStatus1);
    },
    canCleForm() {
      this.auditDialog = false;
      this.ruleForm = {};
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 0.675) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.tip {
  cursor: pointer;
  background: #f2f6fc;
}
/deep/.el-upload {
  border: none;
  height: 180px !important;
}
</style>
<style lang="less">
.el-tooltip__popper {
  max-width: 45% !important;
}
</style>