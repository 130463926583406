import { render, staticRenderFns } from "./customerCenter.vue?vue&type=template&id=f29235f0&scoped=true"
import script from "./customerCenter.vue?vue&type=script&lang=js"
export * from "./customerCenter.vue?vue&type=script&lang=js"
import style0 from "./customerCenter.vue?vue&type=style&index=0&id=f29235f0&prod&lang=less&scoped=true"
import style1 from "./customerCenter.vue?vue&type=style&index=1&id=f29235f0&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f29235f0",
  null
  
)

export default component.exports